import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import Swiper, { SwiperOptions } from 'swiper';

/**
 * 스와이퍼 기본 옵션
 */
const DEFAULT_SWIPER_OPTIONS: SwiperOptions = {
  loop: true,
  centeredSlides: true,
  resizeObserver: true,
  slidesPerView: 1,
};

// FIXME: 표준화
/**
 * 이미지 캐러셀 (슬라이드)
 */
@Component({
  selector: 'trnty-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss'],
})
export class ImageCarouselComponent implements OnInit, AfterViewInit {
  /**
   * 입력 할 스와이퍼 옵션
   */
  inputSwiperOptions?: SwiperOptions;

  /**
   * 스와이퍼 객체
   */
  swiper?: Swiper;

  /**
   * 이미지 목록
   */
  @Input() imgUrlList?: string[];

  /**
   * 스와이퍼 옵션
   */
  @Input() swiperOptions?: SwiperOptions;

  @Input() showPreview?: boolean;

  /**
   * 스와이퍼 초기화 완료시
   */
  @Output() swiperInit = new EventEmitter<Swiper>();

  /**
   * 이전 이미지 버튼
   */
  @ViewChild('leftButton') leftButton?: ElementRef<HTMLButtonElement>;

  /**
   * 다음 이미지 버튼
   */
  @ViewChild('rightButton') rightButton?: ElementRef<HTMLButtonElement>;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.inputSwiperOptions = {
      ...DEFAULT_SWIPER_OPTIONS,
      ...this.swiperOptions,
      // 내비게이션 버튼은 변경 불가
      navigation: {
        prevEl: this.leftButton!.nativeElement,
        nextEl: this.rightButton!.nativeElement,
      },
    };
  }

  /**
   * 스와이퍼 초기화시
   */
  onSwiperInit(swiper: Swiper): void {
    // 스와이퍼 객체를 컴포넌트 바깥으로 전달
    this.swiper = swiper;
    this.swiperInit.emit(swiper);
  }

  onPreviewImageClick(index: number): void {
    this.swiper?.slideToLoop(index, 0);
  }
}
