<!-- 스와이퍼 -->
<swiper-container
  init="false"
  [swiper]="inputSwiperOptions"
  (swiperInit)="onSwiperInit($event)"
  *ngIf="inputSwiperOptions"
>
  <swiper-slide *ngFor="let imgUrl of imgUrlList">
    <pw-img
      [hoverZoom]="false"
      class="carousel-detail-img full-height"
      [src]="imgUrl"
    >
    </pw-img>
    <!-- 스와이퍼 이미지 레이지로딩 -->
    <div class="swiper-lazy-preloader"></div>
  </swiper-slide>
</swiper-container>

<!-- 이전 이미지 버튼 -->
<button #leftButton type="button" class="carousel-navigator-left">
  <img src="/assets/imgs/ic_prev_box.svg" />
</button>

<!-- 다음 이미지 버튼 -->
<button #rightButton type="button" class="carousel-navigator-right">
  <img src="/assets/imgs/ic_next_box.svg" />
</button>

<!-- 페이지 라벨 -->
<div class="index-label" translate="no" [class.with-preview-list]="showPreview">
  <span>{{ (swiper?.realIndex ?? 0) + 1 }}</span>
  &nbsp;/&nbsp;
  <span>{{ imgUrlList?.length || 0 }}</span>
</div>

<div class="image-list" *ngIf="showPreview">
  @for (imgUrl of imgUrlList; let index = $index; track index) {
    <img
      class="preview"
      loading="lazy"
      draggable="false"
      [src]="imgUrl"
      (click)="onPreviewImageClick(index)"
    />
  }
</div>
